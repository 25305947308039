<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12">
                    <ValidationProvider name="housing_room_id" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('housing_rooms')">
                            <HouseRoomSelectbox
                                v-model="formData.housing_room_id"
                                :validate-error="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="name" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('name')">
                            <b-form-input  v-model="formData.name">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>

                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="type" rules="" v-slot="{valid, errors}">
                        <b-form-group :label="$t('type')">
                            <parameter-selectbox v-model="formData.type"
                                                 :validate-error="errors[0]"
                                                 code="housing_bed_types"
                            ></parameter-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="floor" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('fee')">
                            <b-form-input  v-model="formData.fee">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary" @click="storeForm">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
// Other
import {ValidationProvider, ValidationObserver} from "vee-validate"
import HousingBedService from "@/services/HousingBedServices";
import HouseRoomSelectbox from "@/components/interactive-fields/HouseRoomSelectbox.vue";

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        HouseRoomSelectbox
    },
    data() {
        return {
            formData: {
            },
        }
    },
    methods: {
        async storeForm() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid) {
                HousingBedService.store(this.formData)
                    .then(response => {
                        this.$emit('createFormSuccess', true);
                        this.$toast.success(this.$t('api.' + response.data.message));
                    })
                    .catch(e => {
                        this.showErrors(e, this.$refs.formModalValidate);
                    })
                    .finally(() => {
                        this.formLoading = false
                    })
            }
        }
    }
}
</script>
